import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, Button, CssBaseline, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const TYCDocument = () => {
  document.title = "Términos y Condiciones";
  const navigate = useNavigate();
  const returnToCredentials = () => {
    navigate("/recomendanos");
  };
  return (
    <Box sx={{ backgroundColor: "#fafafa" }}>
      <Box sx={{ width: "90%", margin: "0 auto 70px" }}>
        <CssBaseline />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            onClick={returnToCredentials}
            style={{
              fontSize: "25px",
              position: "relative",
              top: "16px",
            }}
            sx={{ minWidth: 0, padding: 0, color: "black", height: 0 }}
          >
            <ArrowBack />
          </Button>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              textAlign: "center",
              fontWeight: "700",
              pb: 6,
              pt: 9,
              width: "100%",
            }}
          >
            Términos y condiciones
          </Typography>
        </div>
        <Typography variant="h6" marginBottom={2}>
          El Programa de Red de Vendedores otorga a cualquier persona, mayor de
          18 años (“Participante”), un Bono único e intransferible por el valor
          de ${process.env.REACT_APP_PRICE_REFERRED} (pesos argentinos). El mismo es comunicado y otorgado por la
          empresa Agencia Programarte SAS, fundadora de Fidel.
        </Typography>
        <Typography variant="h6" marginBottom={2}>
          Para participar, se deberán aceptar los siguientes términos y
          condiciones.
        </Typography>
        <Typography variant="h6">
          El Participante, puede conseguir un bono solo si:
        </Typography>
        <Typography variant="h6" marginBottom={2}>
          Refiere a un usuario, no vinculado anteriormente, a través del
          formulario disponible en los distintos medios (Página Web, App Móvil,
          Sistema ERP) y este contrata por primera vez un plan, de los ofrecidos
          en el año corriente.
        </Typography>
        <Typography variant="h6" marginBottom={2}>
          Ampliación del párrafo anterior.
        </Typography>
        <Typography variant="h6" marginBottom={2}>
          El Participante no podrá reclamar el pago del premio si, anteriormente
          el Usuario potencial fue referido por otra persona ó si ya tuvo
          relación comercial con el producto Fidel.
        </Typography>
        <Typography variant="h6" marginBottom={2}>
          De activarse cualquiera de las dos situaciones, será notificado por el
          ente emisor del programa “Red de Vendedores”.
        </Typography>
        <Typography variant="h6" marginBottom={2}>
          El Participante debe ingresar los datos solicitados del Usuario
          potencial, a fin de contactarlo y generar el vínculo. En caso de no
          obtener respuesta, por parte de este último, queda cancelado el premio
          en cuestión.
        </Typography>
        <Typography variant="h6" marginBottom={2}>
          También se solicitará, por única vez, la cuenta bancaria y correo
          electrónico del “Participante”, para la posterior comunicación y
          transferencia del Premio.
        </Typography>
        <Typography variant="h6" marginBottom={2}>
          La entrega del premio se realizará mediante correo electrónico.
        </Typography>
        <Typography variant="h6" marginBottom={2}>
          Se enviará el comprobante de transferencia bancaria al e-mail de
          contacto del “Participante” una vez que el Usuario potencial contrate
          un plan.
        </Typography>
        <Typography variant="h6" marginBottom={2}>
          Acuerdo válido desde el 1 de enero de 2023 hasta el 31 de diciembre de
          2023.
        </Typography>
      </Box>
    </Box>
  );
};
