import { Box, Button, CssBaseline, Grid, Paper, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from 'yup';
import jwt_decode from "jwt-decode";
import logoFidel from './../../../img/fidelBlanco.png';
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { useStyles } from "./ProfileForm.styles";
import { SnackBarProps } from "../../Lead/LeadForm/LeadForm.types";
import { useEffect, useState } from "react";
import { errorHandling, setTokenVentas } from "../../../api";
import { getColaborator, updateColaborator } from "../../../api/services/collaborator/collaborator.service";
import { CollaboratorData } from "../../../api/services/collaborator/collaborator.types";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomTextField } from "../../../common/CustomTextField";
import networkImage from './../../../img/WorldNetwork.png';

interface UserInfo{
  email:string,
  dni:string,
  name:string,
  lastName:string,
  socialReason:string,
  cbu:string,
}

const validationSchema = yup.object({
  email: yup.string().email('Ingrese un Email válido').required('Email requerido'),
  dni: yup.number().typeError('Sólo se admiten caracteres numéricos').min(4, 'Ingrese un DNI válido').required('DNI requerido'),
  cbu: yup.number().typeError('Sólo se admiten caracteres numéricos').min(4, 'Ingrese un CBU válido')
});

export default function ProfileForm() {
  const classes = useStyles();
  const location = useLocation();
  let params = location.state;
  const navigate = useNavigate();

  const [snackbar, setSnackbar] = useState<SnackBarProps>({} as SnackBarProps);
  const [collaborator, setCollaborator] = useState<CollaboratorData>({} as CollaboratorData);
  const [userId, setUserId] = useState<number>(0);

  const initialValues: UserInfo = { 
    email: collaborator.email ?? '',
    dni: collaborator.DNI?.toString() ?? '',
    name: collaborator.name ?? '',
    lastName: collaborator.lastName ?? '',
    socialReason: collaborator.socialReason ?? '',
    cbu: collaborator.CBU ? collaborator.CBU?.toString() : '',
  };

  useEffect(()=>{
    setTokenVentas(params.token);
    var decoded:any = jwt_decode(params.token);
    let tokenId:number = +decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid']; 
    setUserId(+decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid']);

    getColaborator(tokenId).then(async(data)=>{
      if(data.data){
        setCollaborator(data.data)
      }else{
        setSnackbar({open:true, msg:"Ha ocurrido un error inesperado", severity:"error"});
      }
    }).catch(errorHandling)
  },[]);

  const onSubmit = (values: UserInfo) => {
    let collaborator:CollaboratorData = {
      email : values.email,
      DNI : +values.dni,
      CBU : values.cbu,
      name: values.name,
      lastName: values.lastName,
      socialReason: values.socialReason,
    }

    updateColaborator(collaborator, userId).then((data)=>{
      if(data === 200){
        setSnackbar({open:true, msg:"Colaborador editado correctamente", severity:"success"});
      }else{
        setSnackbar({open:true, msg:"Ha ocurrido un error inesperado", severity:"error"});
      }
    }).catch(errorHandling);
  };

  const handleSnackbar = () => {
    if(snackbar) setSnackbar({} as SnackBarProps)
  }

  const returnToLead = () => {
    let token = params.token;
    navigate("/referir", { state: {token} });
  }

  return (
    <Grid container component="main" sx={{flexDirection: "row"}}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={3}  square className={`background ${classes.containerForm}`}>
        <Box className={classes.formBox}>
          <div className={classes.logoContainer}>
              <a href="https://fidel.com.ar" target="_BLANK"><img src={logoFidel} height="50" alt="Fidel"/></a>
          </div>
          
          <Button onClick={returnToLead} className={classes.arrowBackButton} sx={{minWidth:0, padding:0, color:"#fff", height:0}}><ArrowBackIcon /></Button>
          <Typography component="h2" variant="h6" color={'#fff'}>
              Datos del Influenciador
          </Typography>
          <Box sx={{ mt: 1 }}>
          <Formik
            enableReinitialize={true} 
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
          {({
            errors,
            touched,
          }) => (
            <Form>
              <CustomTextField
                id="email"
                name="email"
                label="Email"
                error={touched.email && Boolean(errors.email)}
                disabled
              />
              <CustomTextField
                id="dni"
                name="dni"
                label="DNI"
                error={touched.dni && Boolean(errors.dni)}
                disabled
              />
              <CustomTextField
                id="name"
                name="name"
                label="Nombre"
                error={touched.name && Boolean(errors.name)}
              />
              <CustomTextField
                id="lastName"
                name="lastName"
                label="Apellido"
                error={touched.lastName && Boolean(errors.lastName)}
              />
              <CustomTextField
                id="socialReason"
                name="socialReason"
                label="Razón Social"
                error={touched.socialReason && Boolean(errors.socialReason)}
              />
              <CustomTextField
                id="cbu"
                name="cbu"
                label="CBU"
                error={touched.cbu && Boolean(errors.cbu)}
              />
              {touched.cbu && errors.cbu && <span className={classes.errorMessage}>{errors.cbu}</span>}
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mt: 3, backgroundColor: "#d17a29", '&:hover': {
                  backgroundColor: '#d17a29',
                }}}
              >
                Modificar
              </Button>
            </Form>
            )}
            </Formik>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={false} sm={4} md={7} className={classes.gridContainer} sx={{flexDirection: "column"}}>
        <article>
          <Typography component="h1" variant="h4" textAlign={"center"} color={'gray'} sx={{fontWeight: 700, marginTop: 5}}>
          ¡Sumate a nuestra Red de Influenciadores!
          </Typography>
          <Typography paragraph={true}  color={'green'} textAlign={"center"} sx={{marginTop: 2, fontSize: 19}}>
            Ayudanos a hacer crecer la comunidad de Fidel y ganá un bono de ${process.env.REACT_APP_PRICE_REFERRED}... <br/> ¡Sólo tenés que cargar los datos del referido!
            </Typography>
        </article>
        <figure className={classes.contImgNetwork}>
          <img src={networkImage} alt="" style={{width: "100%", height: "100%", objectFit: "contain"}}/>
        </figure>
      </Grid>
      {snackbar.open && <Snackbar msg={snackbar.msg} severity={snackbar.severity} handleSnack={handleSnackbar}/>}
    </Grid>
  );
}
